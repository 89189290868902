import { atom } from 'jotai'

interface ProgressiveRevealState {
  counter: number
  productIndex: number | null
  productId: number | null
  searchQuery: string | string[]
  hasShelfBeenViewed: boolean
}

export const initialProgressiveRevealState: ProgressiveRevealState = {
  counter: 0,
  productIndex: null,
  productId: null,
  searchQuery: '',
  hasShelfBeenViewed: false,
}

export const progressiveRevealAtom = atom<ProgressiveRevealState>(
  initialProgressiveRevealState
)
progressiveRevealAtom.debugLabel = 'progressiveRevealAtom'
